export default function scrollGsapImg() {
	if (window.matchMedia("(max-width: 1100px)").matches) return

	gsap.registerPlugin(ScrollTrigger);
	const
		cardsWrapper = document.querySelector("#nosso-ambiente .cards-wrapper");
        if (!cardsWrapper) return
		const col1 = cardsWrapper.querySelector(".col-1"),
		col2 = cardsWrapper.querySelector(".col-2"),
		col3 = cardsWrapper.querySelector(".col-3"),
		col4 = cardsWrapper.querySelector(".col-4");
		col5 = cardsWrapper.querySelector(".col-5");
		// col6 = cardsWrapper.querySelector(".col-6");

	const
		value = 200,
		duration = 40;


	gsap.set(col1, {
		y: - value
	}, "juntas")
	gsap.set(col2, {
		y: value
	}, "juntas")
	gsap.set(col3, {
		y: - value
	}, "juntas")
	gsap.set(col4, {
		y: value
	}, "juntas")
	gsap.set(col5, {
		y: - value
	}, "juntas")
	// gsap.set(col6, {
	// 	y: value
	// }, "juntas")

	const tl =
		gsap
			.timeline({ paused: true, delay: 0, ease: "linear", repeat: -1, yoyo: true, yoyoEase: false })
			.to(col1, {
				duration: duration,
				y: value
			}, "juntas")
			.to(col2, {
				duration: duration,
				y: - value
			}, "juntas")
			.to(col3, {
				duration: duration,
				y: value
			}, "juntas")
			.to(col4, {
				duration: duration,
				y: - value
			}, "juntas")
			.to(col5, {
				duration: duration,
				y: value
			}, "juntas")
			// .to(col6, {
			// 	duration: duration,
			// 	y: - value
			// }, "juntas")

	window.tl = tl


	ScrollTrigger.create({
		trigger: cardsWrapper,
		once: false,
		start: 'top-=100px',
		refreshPriority: 10,
		//markers: true,
		onEnter: function () {
			tl.play()

		},
	});
}