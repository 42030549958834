export default function swipersHome(){
    const swiperMenu = new Swiper(".swiper-menu-home", {
        slidesPerView: 1.2,
        spaceBetween: 16,
        // loop: true,
        // autoplay: {
        //   delay: 3000,
        // },
        pagination: {
            el: "#nosso-menu .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "#nosso-menu .swiper-button-next",
            prevEl: "#nosso-menu .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 1.2,
                spaceBetween: 32,
            },
            768: {
                slidesPerView: 2.2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 3.2,
                spaceBetween: 32,
            },
        },
    });

    const swiperEquipe = new Swiper(".swiper-nossa-equipe", {
        slidesPerView: 2,
        spaceBetween: 8,
        // loop: true,
        // autoplay: {
        //   delay: 3000,
        // },
        pagination: {
            el: "#nossa-equipe .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: "#nossa-equipe .swiper-button-next",
            prevEl: "#nossa-equipe .swiper-button-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
    });

    const swiperHome = new Swiper(".swiper-home", {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
        delay: 3000,
        }
    });

    // const swiperAcompanhe = new Swiper(".swiper-acompanhe-nos", {
    //     slidesPerView: 1.2,
    //     spaceBetween: 8,
    //     // loop: true,
    //     // autoplay: {
    //     //   delay: 3000,
    //     // },
    //     breakpoints: {
    //         640: {
    //             slidesPerView: 2,
    //             spaceBetween: 8,
    //         },
    //         768: {
    //             slidesPerView: 3,
    //             spaceBetween: 8,
    //         },
    //         1024: {
    //             slidesPerView: 4,
    //             spaceBetween: 8,
    //         },
    //     },
    // });

    const swiperTags = new Swiper(".swiper-tags-home", {
        slidesPerView: 'auto',
        loop: true,
        allowTouchMove: false,
        alowtouch: false,
        speed: 5000,
        autoplay: {
            delay: 0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        breakpoints: {
            // 768: {
            //     slidesPerView: 2,
            //     spaceBetween: 40,
            // },
            // 1200: {
            //     slidesPerView: 4,
            //     spaceBetween: 40,
            // },
            // 1440: {
            //     slidesPerView: 6,
            //     spaceBetween: 40,
            // },
            1024: {
                slidesPerView: 6,
            },
        },
    });

    const breakpoint = window.matchMedia("(min-width:1024px)");
    let swiperNumeros;

    const breakpointChecker = function () {
        if (breakpoint.matches === true) {
        if (swiperNumeros !== undefined) swiperNumeros.destroy(true, true);

        return;
        } else if (breakpoint.matches === false) {
        return enableSwiper();
        }
    };

    const enableSwiper = function () {
        swiperNumeros = new Swiper(".swiper-nossos-numeros", {
            slidesPerView: 2,
            spaceBetween: 32,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
            },
        });
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
}