// PAGES
const pageHome = document.querySelector(".body-home");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./modules/mostrarSenha.js"
import showFormErrors from "./modules/showFormErrors.js";
import menu from "./modules/menu.js"
import initCounterUp from "./modules/counter.js"
import swipersHome from "./modules/swipers.js"
import scrollGsapImg from "./modules/scrollGsapImg.js";
import fileDoLiraContato from "./modules/fileDoLiraContato.js";
import Translate from "./modules/translate.js";
import SelectConfig from "./modules/selectConfig.js";
import downloadMenu from "./modules/downloadMenu.js";
import translateMenu from "./modules/translateMenu.js";

downloadMenu()
translateMenu()

mostrarSenha()
showFormErrors()
menu()
fileDoLiraContato()

SelectConfig('.select-lang');
Translate()

if (window.AOS) {
  AOS.init({
    disable: function () {
      var maxWidth = 768;
      return window.innerWidth < maxWidth;
    },
    startEvent: "DOMContentLoaded",
    offset: 30, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 1500, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
  });
}

// SECTIONS
// import salebrandPortfolio from "./salebrand/salebrandPortfolio.js";
// import salebrandSobre from "./salebrand/salebrandSobre.js";
// import salebrandClientes from "./salebrand/salebrandClientes.js";
// import salebrandContato from "./salebrand/salebrandContato.js";

// salebrandPortfolio();
// salebrandSobre();
// salebrandClientes();
// salebrandContato();

// ★ Pages Scopo
if (pageHome) {
  initCounterUp()
  swipersHome()
  scrollGsapImg()
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);
