const translateMenu = () => {
    const links = document.querySelectorAll('.links-wrapper li')
    const lang = document.querySelector("html[lang]")  
 
      // Select the HTML element
      var htmlElement = document.querySelector('html');

      // Create a new MutationObserver with a callback function
      var observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutation) {
              if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
                  // The lang attribute of the HTML element has changed
                  var newLangValue = mutation.target.getAttribute('lang');
                    if(newLangValue === 'en') {
                        links[0].innerHTML = `<a href="#nosso-menu" class="link">About us</a>`
                        links[2].innerHTML = `<a href="#nosso-ambiente" class="link">Home</a>`
                    }
                    if (newLangValue === 'pt'){
                        links[2].innerHTML = `<a href="#nosso-ambiente" class="link">Ambiente</a>`
                    }
                    console.log('lang attribute changed to: ' + newLangValue);
              }
          });
      });
  
      // Configure the observer to watch for attribute changes
      var config = { attributes: true };
  
      // Start observing the HTML element
      observer.observe(htmlElement, config);
  
      // You can later disconnect the observer when you don't need it anymore
      // observer.disconnect();
    
}

export default translateMenu;