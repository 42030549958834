export default function downloadMenu(){
    const menu = document.querySelectorAll('#menu a[href]');
    const links = document.querySelectorAll('.cardapio');
   

    menu?.forEach (el => {
        links?.forEach (link => {
            el?.addEventListener('click', ()=>{
                if (el.innerText === 'Menu') {
                    el.setAttribute('donwload','download')
                    el.setAttribute('target','_blank')
                    el.href = link.href;
                }
            })       
        })
    })

   
}
/*
menu?.forEach (el => {
    if (el.innerText === 'Menu')
    return console.log(el.innerText);
    else
    console.log('item not found');
})
*/